// Czech
export default{
    form: {
        startSurveyButton: "Začněme",
        nextButton: "Další",
        submitAnswerButton: "Odeslat odpověď",
        continueButton: "Pokračovat",
        finishButton: "Dokončit",
        headerPercentageCompletion: 'dokončeno',
        headerQuestionsCompleted: 'otázky',
        headerDefaultErrorMessage: 'Ups! Něco se pokazilo.',
        brandingPoweredBy: 'Poháněno',
        brandingPromoWhySurvey: 'Proč dělat průzkum, když můžete použít <b>{product}</b>?',
        brandingPromoGetStarted: 'Začněte dnes!',
        submissionPending: "Odesílá se váš voiceform. Nezavírejte okno prohlížeče.",
        submissionCanCloseWindow: "Váš voiceform byl úspěšně odeslán. Nyní můžete zavřít okno 👍",
        mediaAttachmentOverlayCloseButton: "Zavřít",
        expiredFormHeader: "Děkujeme za návštěvu!",
        expiredFormMessage: "Zdá se, že tento průzkum již vypršel. Kontaktujte prosím tvůrce voiceformu a informujte ho.",
        notPublishedFormHeader: "Voiceform není publikován!",
        notPublishedFormMessage: "Zdá se, že tento průzkum není publikován. Kontaktujte prosím tvůrce voiceformu a informujte ho.",
        notPublishedFormRetryButton: "Zkusit znovu",
        errorFormHeader: "Ups!",
        errorFormMessage: "Něco se pokazilo. Zkuste to prosím později.",
        errorFormRetryButton: "Zkusit znovu",
        emptyFormHeader: "Děkujeme za návštěvu!",
        emptyFormMessage: "Ups! Zdá se, že tento formulář je momentálně prázdný, bez otázek nebo polí k vyplnění. Omlouváme se za způsobené nepříjemnosti. Kontaktujte prosím tvůrce tohoto formuláře a informujte ho o problému. Děkujeme za vaše pochopení a spolupráci!",

        restoreFormHeader: "Vypadá to, že máte rozpracovaný formulář!",
        restoreFormMessage: "Chcete pokračovat tam, kde jste skončili?",
        restoreFormButton: "Pokračovat ve formuláři",
        restoreFormButtonNew: "Začít znovu",

        audioPermissionHeader: "Chcete odpovědět hlasem?",
        audioPermissionMessage: "Mluvení umožní rychlé a snadné odpovědi.",
        audioPermissionAcceptButton: "Ano, chci",
        audioPermissionDenyButton: "Ne, děkuji",
        audioPermissionErrorHeader: "Mikrofon není dostupný",
        audioPermissionErrorMessage: "Zkontrolujte prosím oprávnění prohlížeče, abyste povolili přístup k mikrofonu.",
        audioPermissionErrorButton: "OK",
        audioRecorderFailedUpload: "Nepodařilo se nahrát záznam",
        silenceDetectorError: "Neslyšíme vás. Zkontrolujte prosím nastavení mikrofonu. Tip: Možná používáte externí mikrofon.",
        audioRecorderButtonRecord: "Nahrávat",
        audioRecorderButtonRecordMore: "Nahrát více",
        voiceResponseSelectionMessage: "Vyberte, jak chcete odpovědět...",
        voiceResponseSelectionOrDivider: "nebo",
        voiceResponseTextInputPlaceholder: "Zadejte odpověď",
        voiceResponseTextInputCharacterCounterMinMessage: "Zadejte prosím alespoň {min} znaků",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count} znaků (minimálně {min} znaků)",
        voiceResponseTextInputCharacterValidationMessage: "{count} znaků (minimálně {min} znaků)",

        // checkbox
        checkboxValidationTooFew: "Musíte vybrat alespoň {min} možnost(i)",
        checkboxValidationTooMany: "Musíte vybrat maximálně {max} možnost(i)",
        checkboxNoneOfTheAboveOption: "Žádná z výše uvedených",
        checkboxInvalidAnswer: "Neplatná odpověď.",

        // datepicker
        datePickerPlaceholder: "Vyberte datum",

        // dropdown
        // email
        emailLabel: "E-mail",
        emailInvalid: "Neplatný e-mail.",
        // file-upload
        fileUploadPluginNameCamera: "Kamera",
        fileUploadPluginNameCameraVideo: "Nahrát video",
        fileUploadPluginScreenCast: "Nahrávání obrazovky",
        fileUploadVideoImportFilesDefault: 'Nahrajte nebo zaznamenejte video přes:',
        fileUploadVideoImportFilesLocalFilesDisabled: 'Vyberte možnost nahrávání videa:',
        fileUploadVideoImportFilesNoCamera: 'Klikněte na tlačítko pro záznam obrazovky',
        fileUploadVideoImportFilesNoScreenCast: 'Klikněte na tlačítko pro záznam videa',
        fileUploadVideoImportFilesNoCameraAndScreenCast: 'Žádné možnosti pro záznam videa nejsou dostupné',

        // matrix
        matrixValidationMessage: "Odpověď je platná.",
        matrixRow: "Řádek",
        matrixColumn: "Sloupec",
        matrixRowRequired: "Řádek {rowTitle} je povinný.",
        matrixRadioGroup: "Radio skupina",
        matrixCheckboxGroup: "Checkbox skupina",
        matrixGroupRequired: "Pro řádek {rowTitle}. {type} {groupTitle} je povinný.",
        matrixColumnRequired: "Pro řádek {rowTitle}. Sloupec {columnTitle} je povinný.",
        matrixColumnInvalid: "Pro řádek {rowTitle}. Sloupec {columnTitle} je neplatný.",
        matrixRequired: "Povinné.",
        matrixNumericMustBeNumber: "Musí být číslo.",
        matrixNumericMustBeGreaterThenMin: "Musí být větší než {min}.",
        matrixNumericMustBeLessThenMax: "Musí být menší než {max}.",
        matrixNumericMustBeInteger: "Musí být celé číslo.",
        matrixNumericInvalidNumber: "Neplatné číslo.",

        // name
        nameLabel: "Jméno",
        nameInvalid: "Zadejte prosím jméno a příjmení.",

        // nps
        npsNotLikely: "Velmi nepravděpodobné",
        npsExtremelyLikely: "Velmi pravděpodobné",

        // numeric input
        numericInputRequired: "Povinné.",
        numericInputMustBeNumber: "Musí být číslo.",
        numericInputMustBeGreaterThenMin: "Musí být větší než {min}.",
        numericInputMustBeLessThenMax: "Musí být menší než {max}.",
        numericInputMustBeInteger: "Musí být celé číslo.",
        numericInputInvalidNumber: "Neplatné číslo.",
        numericInputPlaceholder: "Zadejte číslo",

        // phone
        phoneInvalid: "Telefonní číslo je neplatné.",
        phoneCountrySelectorLabel: 'Kód země',
        phoneCountrySelectorError: 'Vyberte zemi',
        phoneNumberLabel: 'Telefonní číslo',
        phoneExample: 'Příklad:',

        // boolean
        booleanYesLabel: "Ano",
        booleanNoLabel: "Ne",

        //questionStep
        questionStepAudioQuestionLabel: "Audio otázka",

        // errors
        invalidPhoneNumber: "{phone} je neplatné telefonní číslo.",
        invalidEmail: "{email} je neplatná e-mailová adresa.",
        questionIsRequired: "Otázka je povinná.",
        answerIsNotValid: "Odpověď není platná.",
        unfinishedProbingDialogError: "Dokončete prosím dialog.",
        cannotResumePartialResponse: "Nelze obnovit částečnou odpověď.",
        failedToSubmitForm: "Nepodařilo se odeslat formulář. Zkontrolujte připojení k internetu a zkuste to znovu."
    }
}
